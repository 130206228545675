import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}

//#region BRANDS
export const getBrandsListService = (model) => {
  const url = ApiRouters.BRANDS;
  return api.get(url, { params: model });
};

export const getToken = () => {
  const url = "http://localhost:8080/auth/token";
  return api.get(url);
};

export const deleteBrandService = (model) => {
  const url = `${ApiRouters.BRANDS}`;
  return api.delete(url, { params: model });
};
export const getBrandService = (id) => {
  const url = `${ApiRouters.BRANDS}/${id}`;
  return api.get(url);
};
export const createBrandService = (body) => {
  const url = ApiRouters.BRANDS;
  return api.post(url, body);
};
export const updateBrandService = (body) => {
  const url = `${ApiRouters.BRANDS}/${body.id}`;
  return api.put(url, body);
};
export const importBrandsService = (body) => {
  const url = process.env.REACT_APP_API_KEY + `${ApiRouters.BRANDS}/ImportBrand`;
  return axios(optionsAxios(url, "post", null, body));
};
export const downloadBrandTemplateService = () => {
  const url =
    process.env.REACT_APP_API_KEY + `${ApiRouters.BRANDS}/Template/ImportBrand`;
  return axios(optionsAxios(url, "get", null, null));
};
//#endregion

//#region SALES
export const getSalesListService = (model) => {
  const url = `${ApiRouters.SALES}`;
  return api.get(url, { params: model });
};
export const syncSalesService = (model) => {
  const url = `${ApiRouters.SALES}`;
  return api.post(url, model.body);
};
//#endregion

//#region BUDGET
export const getBudgetsListService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets`;
  return api.get(url, { params: model });
};
export const deleteBudgetService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets`;
  return api.delete(url, { params: model });
};
export const createBudgetService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets`;
  return api.post(url, model.body);
};
export const updateBudgetService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets`;
  return api.put(url, model.body);
};
export const getBudgetsForecastService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets/${model.budgetId}/forecast`;
  return api.get(url);
};
export const updateBudgetsForecastService = (model) => {
  const url = `${ApiRouters.BRANDS}/${model.id}/budgets/${model.budgetId}/forecast`;
  return api.put(url, model.body);
};
//#endregion

//#region EXPENSE
export const getExpensesListService = (model) => {
  const url = `${ApiRouters.EXPENSES}`;
  return api.get(url, { params: model });
};
export const getExpenseService = (expenseId) => {
  const url = `${ApiRouters.EXPENSES}/${expenseId}`;
  return api.get(url);
};
export const deleteExpenseService = (model) => {
  const url = `${ApiRouters.EXPENSES}`;
  return api.delete(url, { params: model });
};
export const createExpenseService = (model) => {
  const url = `${ApiRouters.EXPENSES}/${model.budgetId}`;
  return api.post(url, model.body);
};
export const updateExpenseService = (model) => {
  const url = `${ApiRouters.EXPENSES}/${model.id}`;
  return api.put(url, model.body);
};
export const importExpensesService = (model) => {
  const url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.EXPENSES}/ImportExpense/${model.budgetId}`;
  return axios(optionsAxios(url, "post", null, model.body));
};
export const downloadExpenseTemplateService = () => {
  const url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.EXPENSES}/Template/ImportExpense`;
  return axios(optionsAxios(url, "get", null, null));
};
//#endregion
