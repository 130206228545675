import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}

export const [GET_LIST, EXPORT] = [1, 2];
export const [TOTAL, MONTHLY, EXPENSE, SALE, QUATER] = [
  "1",
  "2",
  "3",
  "4",
  "5",
];

export const getReportsService = (model) => {
  let url = getReportUrl(model?.type, GET_LIST);
  return api.get(url, { params: model });
};

export const exportReportService = (model) => {
  var url = getReportUrl(model?.type, EXPORT);
  return axios(optionsAxios(url, "get", model, null));
};

export const getReportUrl = (type = null, actions = null) => {
  let url = null;
  if (actions === GET_LIST) {
    url = `${ApiRouters.REPORT}/`;
    switch (type) {
      case TOTAL:
        url = url + "total-and-forecasts";
        break;

      case EXPENSE:
        url = url + `expenses/list`;
        break;

      case MONTHLY:
        url = url + `monthly/list`;
        break;

      case SALE:
        url = url + `sale/list`;
        break;

      case QUATER:
        url = url + `quater/list`;
        break;

      default:
        break;
    }
  } else {
    url = process.env.REACT_APP_API_KEY + `${ApiRouters.REPORT}/`;
    switch (type) {
      case TOTAL:
        url = url + "total-and-forecast-export";
        break;

      case EXPENSE:
        url = url + `expenses/export-excel`;
        break;

      case MONTHLY:
        url = url + `monthly/export-excel`;
        break;

      case SALE:
        url = url + `sale/export-excel`;
        break;

      case QUATER:
        url = url + `quater/export-excel`;
        break;

      default:
        break;
    }
  }
  return url;
};
