import actions from "./actions";

const initState = {
  brands: {
    list: [],
    totalItems: 0,
    loading: false,
  },
  budgets: {
    list: [],
    totalItems: 0,
    loading: false,
    listYearsExisted: [],
    brandCurrency: null,
  },
  budgetForecast: {
    loading: false,
    data: {},
  },
  expenses: {
    list: [],
    totalItems: 0,
    loading: false,
    budgetYear: null,
    brandName: null,
    brandCurrency: null,
  },
  expensesCategories: {
    loading: false,
    mainCategories: [],
    expensesCategories: [],
    subCategories: [],
  },
};

const [MAIN_CATEGORIES, CATEGORIES, SUB_CATEGORIES] = [1, 2, 3];

export default function brandsReducer(state = initState, action) {
  switch (action.type) {
    //#region BRANDS
    case actions.GET_BRANDS_LIST:
      return { ...state, brands: { ...state.brands, loading: true } };
    case actions.GET_BRANDS_LIST_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        brands: {
          ...state.brands,
          list: res,
          loading: false,
          totalItems: totalItems,
        },
      };
    }
    case actions.GET_BRANDS_LIST_ERROR:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          totalItems: 0,
          brandName: null,
          list: [],
        },
      };
    //#endregion

    //#region SALE
    case actions.GET_SALES:
      return { ...state, budgets: { ...state.budgets, loading: true } };
    //#endregion

    //#region BUDGETS
    case actions.GET_BUDGETS_LIST:
      return { ...state, budgets: { ...state.budgets, loading: true } };
    case actions.GET_BUDGETS_LIST_SUCCESS: {
      let { data, totalItems, brandName, listYearsExist, brandCurrency } =
        action.payload;
      let res = data ? data : [];
      let array = [];

      listYearsExist?.length &&
        listYearsExist.map((item) => {
          return array.push(item?.budget_year);
        });

      return {
        ...state,
        budgets: {
          ...state.budgets,
          list: res,
          loading: false,
          totalItems: totalItems,
          brandName: brandName,
          listYearsExisted: [...array],
          brandCurrency: brandCurrency,
        },
      };
    }

    case actions.GET_BUDGETS_LIST_ERROR:
      return {
        ...state,
        budgets: {
          ...state.budgets,
          loading: false,
          totalItems: 0,
          list: [],
          brandName: null,
          listYearsExisted: [],
          brandCurrency: null,
        },
      };

    case actions.GET_BUDGET_FORECAST:
      return {
        ...state,
        budgetForecast: { ...state.budgetForecast, loading: true },
      };
    case actions.GET_BUDGET_FORECAST_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return {
        ...state,
        budgetForecast: {
          ...state.budgetForecast,
          data: res,
          loading: false,
        },
      };
    }
    case actions.GET_BUDGET_FORECAST_ERROR:
      return {
        ...state,
        budgetForecast: {
          ...state.budgetForecast,
          loading: false,
          data: [],
        },
      };
    //#endregion

    //#region EXPENSES
    case actions.GET_EXPENSES_LIST:
      return { ...state, expenses: { ...state.expenses, loading: true } };
    case actions.GET_EXPENSES_LIST_SUCCESS: {
      let { data, totalItems, brandName, budgetYear, brandCurrency } =
        action.payload;
      let res = data ? data : [];
      return {
        ...state,
        expenses: {
          ...state.expenses,
          list: res,
          loading: false,
          totalItems: totalItems,
          brandName: brandName,
          budgetYear: budgetYear,
          brandCurrency: brandCurrency,
        },
      };
    }
    case actions.GET_EXPENSES_LIST_ERROR:
      return {
        ...state,
        expenses: {
          ...state.expenses,
          loading: false,
          totalItems: 0,
          list: [],
          brandName: null,
          budgetYear: null,
          brandCurrency: null,
        },
      };
    //#endregion

    //#region CATEGORIES
    case actions.GET_EXPENSES_CATEGORIES:
      return {
        ...state,
        expensesCategories: { ...state.expensesCategories, loading: true },
      };

    case actions.GET_EXPENSES_CATEGORIES_SUCCESS: {
      let { data, level } = action.payload;
      let res = data ? data : [];
      switch (level) {
        case MAIN_CATEGORIES:
          return {
            ...state,
            expensesCategories: {
              ...state.expensesCategories,
              mainCategories: res,
              loading: false,
            },
          };
        case CATEGORIES:
          return {
            ...state,
            expensesCategories: {
              ...state.expensesCategories,
              categories: res,
              loading: false,
            },
          };
        case SUB_CATEGORIES:
          return {
            ...state,
            expensesCategories: {
              ...state.expensesCategories,
              subCategories: res,
              loading: false,
            },
          };

        default:
          return {
            ...state,
            expensesCategories: { ...state.expensesCategories, loading: false },
          };
      }
    }
    case actions.GET_EXPENSES_CATEGORIES_ERROR:
      return {
        ...state,
        expensesCategories: {
          ...state.expensesCategories,
          loading: false,
          mainCategories: [],
          categories: [],
          subCategories: [],
        },
      };

    case actions.RESET_EXPENSES_CATEGORIES:
      return {
        ...state,
        expensesCategories: {
          ...state.expensesCategories,
          categories: [],
          subCategories: [],
        },
      };

    case actions.RESET_EXPENSES_SUB_CATEGORIES:
      return {
        ...state,
        expensesCategories: { ...state.expensesCategories, subCategories: [] },
      };
    //#endregion

    default:
      return state;
  }
}
