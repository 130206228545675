import * as brandsService from "@iso/services/brands";
import * as commonServices from "@iso/services/common";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD BRANDS
export function* getBrandsListSaga() {
  yield takeEvery(actions.GET_BRANDS_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(brandsService.getBrandsListService, model);

      if (response.data.success) {
        const { list: data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_BRANDS_LIST_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_BRANDS_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_BRANDS_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteBrandSaga() {
  yield takeEvery(
    actions.DELETE_BRAND,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.deleteBrandService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createBrandSaga() {
  yield takeEvery(
    actions.CREATE_BRAND,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        const response = yield call(brandsService.createBrandService, body);

        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateBrandSaga() {
  yield takeEvery(
    actions.UPDATE_BRAND,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.updateBrandService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* importBrandsSaga() {
  yield takeEvery(
    actions.IMPORT_BRANDS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        const response = yield call(brandsService.importBrandsService, body);

        if (response.status === 201) {
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `ImportBrandsResult_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          yield _cbSuccess();
        } else {
          if (response.status === 202) {
            _cbError("FILE_MALFORMED");
          }
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* downloadBrandTemplateSaga() {
  yield takeEvery(
    actions.DOWNLOAD_BRAND_TEMPLATE,
    function* ({ _cbSuccess, _cbError }) {
      try {
        const response = yield call(brandsService.downloadBrandTemplateService);

        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `TemplateImportBrands_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data) yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export function* getSalesListSaga() {
  yield takeEvery(actions.GET_SALES, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(brandsService.getSalesListService(), model);

      if (response.data.success) {
        yield put({
          type: actions.GET_SALES,
          payload: response.data,
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_SALES,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_SALES,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

//#region CRUD BUDGETS
export function* getBudgetsListSaga() {
  yield takeEvery(actions.GET_BUDGETS_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(brandsService.getBudgetsListService, model);

      if (response.data.success) {
        const {
          list: data,
          total: totalItems,
          brandName,
          listYearsExist,
          brandCurrency,
        } = response.data.data;
        yield put({
          type: actions.GET_BUDGETS_LIST_SUCCESS,
          payload: {
            data,
            totalItems,
            brandName,
            listYearsExist,
            brandCurrency,
          },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_BUDGETS_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_BUDGETS_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteBudgetSaga() {
  yield takeEvery(
    actions.DELETE_BUDGET,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.deleteBudgetService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createBudgetSaga() {
  yield takeEvery(
    actions.CREATE_BUDGET,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.createBudgetService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateBudgetSaga() {
  yield takeEvery(
    actions.UPDATE_BUDGET,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.updateBudgetService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getBudgetForecastSaga() {
  yield takeEvery(
    actions.GET_BUDGET_FORECAST,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          brandsService.getBudgetsForecastService,
          model
        );

        if (response.data.success) {
          const { data } = response.data;
          yield put({
            type: actions.GET_BUDGET_FORECAST_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_BUDGET_FORECAST_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_BUDGET_FORECAST_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateBudgetForecastSaga() {
  yield takeEvery(
    actions.UPDATE_BUDGET_FORECAST,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          brandsService.updateBudgetsForecastService,
          model
        );

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

//#region CRUD EXPENSES
export function* getExpensesListSaga() {
  yield takeEvery(actions.GET_EXPENSES_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(brandsService.getExpensesListService, model);

      if (response.data.success) {
        const {
          list: data,
          total: totalItems,
          budgetYear,
          brandName,
          brandCurrency,
        } = response.data.data;
        yield put({
          type: actions.GET_EXPENSES_LIST_SUCCESS,
          payload: {
            data,
            totalItems,
            budgetYear,
            brandName,
            brandCurrency,
          },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_EXPENSES_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_EXPENSES_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteExpenseSaga() {
  yield takeEvery(
    actions.DELETE_EXPENSE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.deleteExpenseService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createExpenseSaga() {
  yield takeEvery(
    actions.CREATE_EXPENSE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.createExpenseService, model);

        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateExpenseSaga() {
  yield takeEvery(
    actions.UPDATE_EXPENSE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.updateExpenseService, model);

        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* importExpensesSaga() {
  yield takeEvery(
    actions.IMPORT_EXPENSES,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(brandsService.importExpensesService, model);

        if (response.status === 201) {
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `ImportExpensesResult_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          yield _cbSuccess();
        } else {
          if (response.status === 202) {
            _cbError("FILE_MALFORMED");
          }
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* downloadExpenseTemplateSaga() {
  yield takeEvery(
    actions.DOWNLOAD_EXPENSE_TEMPLATE,
    function* ({ _cbSuccess, _cbError }) {
      try {
        const response = yield call(
          brandsService.downloadExpenseTemplateService
        );

        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `TemplateImportExpenses_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data) yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getExpensesCategoriesSaga() {
  yield takeEvery(
    actions.GET_EXPENSES_CATEGORIES,
    function* ({ payload, _cbError }) {
      try {
        const { model } = payload;
        const response = yield call(commonServices.getCategories, model);
        if (response.data?.success) {
          const data = response.data?.data;
          const level = model.level;
          yield put({
            type: actions.GET_EXPENSES_CATEGORIES_SUCCESS,
            payload: { data, level },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_EXPENSES_CATEGORIES_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_EXPENSES_CATEGORIES_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getBrandsListSaga),
    fork(deleteBrandSaga),
    fork(createBrandSaga),
    fork(updateBrandSaga),
    fork(importBrandsSaga),
    fork(downloadBrandTemplateSaga),

    fork(getBudgetsListSaga),
    fork(deleteBudgetSaga),
    fork(createBudgetSaga),
    fork(updateBudgetSaga),
    fork(getBudgetForecastSaga),
    fork(updateBudgetForecastSaga),

    fork(getExpensesListSaga),
    fork(deleteExpenseSaga),
    fork(createExpenseSaga),
    fork(updateExpenseSaga),
    fork(importExpensesSaga),
    fork(downloadExpenseTemplateSaga),
    fork(getExpensesCategoriesSaga),
  ]);
}
