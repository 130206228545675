const baseRouter = "/api/";

export const ApiRouters = {
  LOGIN: baseRouter + "Auth/login",
  FORGOT_PASSWORD: baseRouter + "Auth/ForgotPassword",
  CHECK_TOKEN_RESET_PW: baseRouter + "Auth/CheckResetPassword",
  RESET_PASSWORD: baseRouter + "Auth/ResetPassword",

  USER: baseRouter + "User",
  CATEGORY: baseRouter + "Category",
  COMMON: baseRouter + "Common",
  CONSIGNEE: baseRouter + "Consignee",
  SUPPLIERS: baseRouter + "Suppliers",
  MARKETING_ITEM: baseRouter + "MarketingItem",
  BRANDS: baseRouter + "Brand",
  EXPENSES: baseRouter + "Expense",
  INVOICE: baseRouter + "Invoice",
  REPORT: baseRouter + "reports",
  QUOTES: baseRouter + "Quotes",
  QUOTE_ITEMS: baseRouter + "QuoteItems",
  SALES: baseRouter + "Sales",
};
