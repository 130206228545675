import 'antd/dist/antd.min.css';
import { createGlobalStyle } from "styled-components";
import { font, palette } from "styled-theme";

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: ${palette("color", 14)};
  }

  .ant-table-wrapper {
    &.bordered-table {
      .ant-table-thead > tr {
      border: 1px solid ${palette("color", 15)};
      th:not(:last-child) {
        border-right: 1px solid ${palette("color", 15)};
        }
        th {
          color: ${palette("color", 15)};
          border-bottom: 1px solid ${palette("color", 15)};
          background: ${palette("color", 14)};
          white-space: break-spaces;
          text-align: center;
          padding: 4px;
        }
      }
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: ${palette("color", 15)};
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: ${palette("color", 14)};
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
    &-menu {
      border-radius: 4px !important;
      &-item {
          &:hover {
          color: ${palette("color", 19)};
        }
        &-has-total {
          justify-content: flex-start;
          display: flex;
          align-items: center;
          .total-items {
            margin-left: 12px;
            border-radius: 50%;
            width: 24px;
            height: 24px;

            background-color: ${palette("primary", 0)};
            color: ${palette("color", 14)};
            font-size: 11px;
            font-weight: 500;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
    .ant-popover-buttons {
      .ant-btn {
        border: 0;
        &:not(:last-child) {
          margin-right: 4px;
        }
        span {
          text-decoration: underline;
        }
        &-primary {
          border-radius: 4px;
          padding: 2px 12px;
          height: auto;
          span {
            text-decoration: none;
          }
        }
      }
    }
    .ant-popover-inner {
      border-radius: 4px;
    }
    .ant-popover-content {
      .ant-popover-message .categories-confirm {
        .main-title {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  
  .budget-wrap {
    .ant-modal-body {
      height: 70vh;
      overflow: auto;
    }
  }
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-close {
          top: -5px;
        }
        .ant-modal-header, .ant-modal-footer {
          padding: 10px 16px;
        }
        .ant-modal-body {
          padding: 16px;
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
          .password-reset-here {
            background: rgba(0, 177, 106, 0.1);
            color: #00b16a;
            margin-bottom: 16px;

            width: fit-content;
            padding: 2px 16px;
            border-radius: 4px;
            .anticon {
              margin-right: 4px;
            }
            i {
              font-weight: 600;
            }
          }
          .reset-password {
            height: 100%;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            .reset-btn {
              height: 35px;
              background: #4482ff;
              color: ${palette("color", 14)};
              border-radius: 4px;
              border: 0;
              &:focus,
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      &.no-modal-footer .ant-modal-content {
        .ant-modal-footer {
          display: none !important;
        }
      }
      &.modal-expenses .ant-modal-footer {
        padding: 16px;
        border-top: 1px solid ${palette("border", 5)};
      }
      &.modal-confirm-delete {
        .ant-modal-content {
          position: relative;
          .icon-delete-outer {
            position: absolute;
            top: -60px;
            left: 115px;
            &.category-type {
              left: 145px;
            }
          }
          .ant-modal-footer {
            border-top: 0;
          }
        }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${"" /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* ::-webkit-scrollbar {
  width: 10px;
  &-track {
  background: #f1f1f1;
  }
  &-thumb {
    background: #888;
    &:hover {
      background: #555;
    }
  }
} */
`;

export default GlobalStyles;
